.LoadingOverlay {
	background: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 6;
	display: flex;
	align-items: center;
}
