//responsive fixed sticky

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@if ($infix != ''){
			.fixed#{$infix}-top{
				position: fixed;
				top: 0;
				right: 0;
				left: 0;
				z-index: $zindex-fixed;
			}
			.sticky#{$infix}-top{
				@supports (position: sticky) {
					position: sticky;
					top: 0;
					z-index: $zindex-sticky;
				}
			}
		}
	}
}