/** Mixin per centrare elementi assoluti sia su asse x che y **/
@mixin absolute-center-el($pos: xy) {
	position: absolute;
	@if $pos == xy {
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%); 
		transform: translate(-50%, -50%);
	}
	@if $pos == x {
		left: 50%;
		-webkit-transform: translateX(-50%); 
		transform: translateX(-50%);
	}
	@if  $pos == y {
		top: 50%;
		-webkit-transform: translateY(-50%); 
		transform: translateY(-50%);
	}
}

.absolute-center {
	@include absolute-center-el();
}

.absolute-center-x {
	@include absolute-center-el(x);
}

.absolute-center-y {
	@include absolute-center-el(y);
}



@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		// Common values
		@each $position in $positions {
			.position#{$infix}-#{$position} { position: $position !important; }
		}
  }
}
