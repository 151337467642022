/**
	* Variabili per gli headings negli entry content
*/
$entry-headings-base-top: 1.8rem !default;
$entry-headings-base-bottom: 1rem !default;

/*variabili rewrite di core bootstrap*/

/**
	* Variabili per Tipography
*/
$line-height-base: 1.8 !default;

/*nuovi breakpoints xxl*/

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px,
) !default;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1530px,
) !default;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "_variables_extends.scss";