@import '../../styles/variables';

.Footer {
	a {
		color: $light;
	}
	hr{
		border-color: $light;
	}
}
