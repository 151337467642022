//navbar primary
.navbar-primary {
  .navbar-brand {
    color: $navbar-primary-brand-color;

    @include hover-focus {
      color: $navbar-primary-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-primary-color;

      @include hover-focus {
        color: $navbar-primary-hover-color;
      }

      &.disabled {
        color: $navbar-primary-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-primary-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-primary-color;
    border-color: $navbar-primary-toggler-border-color;
  }


  .navbar-text {
    color: $navbar-primary-color;
    a {
      color: $navbar-primary-active-color;

      @include hover-focus {
        color: $navbar-primary-active-color;
      }
    }
  }
}
.navbar-toggler-icon {
  height: auto;
}