// Responsive for borders

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@if ($infix != ''){
			.border#{$infix} { border: $border-width solid $border-color !important; }
			.border#{$infix}-t { border-top: $border-width solid $border-color !important; }
			.border#{$infix}-r  { border-right: $border-width solid $border-color !important; }
			.border#{$infix}-b { border-bottom: $border-width solid $border-color !important; }
			.border#{$infix}-l   { border-left: $border-width solid $border-color !important; }
		}
  }
}