// border radius responsive

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $size, $length in $spacers {
			.border-radius#{$infix}-#{$size} { 
				@include border-radius($length !important);
			}
			.border-radius-t#{$infix}-#{$size} { 
				@include border-top-radius($length !important);
			}
			.border-radius-r#{$infix}-#{$size} { 
				@include border-right-radius($length !important);
			}
			.border-radius-b#{$infix}-#{$size} { 
				@include border-bottom-radius($length !important);
			}
			.border-radius-l#{$infix}-#{$size} { 
				@include border-left-radius($length !important);
			}
		}
  }
}