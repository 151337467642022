@import "../../../styles/variables";
.Cartbox {
	width: 300px;
	height: 100%;
	position: fixed;
	transition: 0.25s ease-in-out;
	overflow-x: hidden;
	right: 0;
	top: 0;
	transform: translateX(300px);
	background-color: $light;
	box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.4);
	&.open {
		transform: translateX(0);
	}
	.container {
		padding: 15px;
	}
}
